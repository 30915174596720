<template>
    <div class="col-12 col-md-8 col-lg-6 content mb-20 mb-lg-0 text-center">
        <!-- 404 Page not found -->
    </div>
</template>

<script>
    export default {
        name: 'PageNotFound',
        metaInfo() {
            // let title = "All Cricket Matches, Score, Update, Schedule, Upcoming";
            // let description = "Get all the latest cricket news, live scores, results, match schedules, articles, player & team updates, and cricket videos - PlanetCricket";
            // return {
            //     title: title,
            //     meta: this.$getMetaTags(title, description)
            // }
        },
    }
</script>
